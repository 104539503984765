
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { checkPermission, checkPermissionUpdate } from "@/core/helpers/helps";
  import { useI18n } from "vue-i18n";
  import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";
  import { useStore } from "vuex";
  import images from "@/assets/ts/images.js";
  import { Actions, Mutations } from "@/store/enums/StoreEnums";
  import { DrawerModeEnum } from "@/store/modules/EarningStatusModules";
  import ElInputVeeValidate from "@/components/forms/ElInputVeeValidate.vue";
  import { Form } from "vee-validate";
  import { useRoute } from "vue-router";
  import { toastSuccess, toastError } from "@/core/services/Alert";
  import * as Yup from "yup";
  
  export default {
    components: { ElInputVeeValidate },
    name: "category-value",
    setup() {
      const { t } = useI18n();
      const store = useStore();
      const route = useRoute();
      let formAsetData = reactive({
        _id: null,
        name: "",
        description: "",
      });
      const categoryValueId = ref(route.params.id);
      const dimentionValueId = ref("");
  
      const drawer = computed({
        get: () => store.getters.getEarningStatusDrawerState,
        set: (v) => store.dispatch(Actions.UPDATE_EARNING_STATUS_DRAWER, v),
      });
  
      const drawerStatus = computed(
        () => store.getters.getEarningStatusDrawerMode
      );
      const dimentionCategories = computed(
        () => store.getters.getAllDimensionCategoriesList
      );
      const activeWorkspace = computed(() => store.getters.getActiveWorkspace);
  
      function closeDrawer() {
        store.dispatch(Actions.UPDATE_EARNING_STATUS_DRAWER, false);
        formAsetData._id = null;
        formAsetData.name = "";
        formAsetData.description = "";
        getAssetDetail();
        getAssetsList();
      }
  
      const statusPerolehan = ref("");
      const listStatusPerolehan = [
        { id: 1, name: "Pembelian / Investasi", code: "PI" },
        { id: 2, name: "Hibah", code: "HI" },
        { id: 3, name: "Hasil Perjanjian", code: "HP" },
        { id: 4, name: "Putusan Pengadilan", code: "PP" },
        { id: 5, name: "Penyertaan Modal Negara", code: "PMN" },
      ];
  
      function openDrawer(data, mode = "add") {
        if (mode === "edit") {
          formAsetData._id = data._id;
          formAsetData.name = data.name;
          formAsetData.description = data.description;
        }
  
        store.dispatch(Actions.UPDATE_EARNING_STATUS_DRAWER, true);
        store.dispatch(Actions.UPDATE_EARNING_STATUS_DRAWER_MODE, mode);
      }
  
      onMounted(() => {
        getAssetDetail();
        getAssetsList();
      });
  
      const getAssetsList = async () => {
        await nextTick();
        if (route.params.id == "asset-dimension") {
          store.dispatch(
            Actions.GET_DIMENSION_UOM_LIST,
            activeWorkspace.value._id
          );
        } else {
          store.dispatch(Actions.GET_ASSET_CATEGORY_LIST, categoryValueId.value);
        }
      };
  
      const getJenisAset = computed(() => {
        return store.getters.getListCategory;
      });
  
      const getAssetDetail = async () => {
        await nextTick();
        if (route.params.id == "asset-dimension") {
          store.commit(Mutations.SET_STATUS_CATEGORY, {
            // _id: "631689248c130d3fa0416184",
            name: "Dimensi Aset",
            is_multiple_choice: false,
            description: "Dimensi aset yang akan digunakan untuk setiap aset",
            code: "",
            is_active: true,
            channel_id: null,
            created_at: "",
            updated_at: "",
            __v: 0,
          });
          setCurrentPageBreadcrumbs("Dimensi Aset", [
            { title: "common.setting", path: "/setting" },
          ]);
  
          // store.dispatch(Actions.GET_CATEGORIES_ASET_DIMENSION, activeWorkspace.value._id)
        } else {
          store
            .dispatch(Actions.GET_STATUS_CATEGORY, categoryValueId.value)
            .then(() => {
              setCurrentPageBreadcrumbs(assetInfo?.value.name, [
                { title: "common.setting", path: "/setting" },
              ]);
            });
        }
      };
  
      const assetInfo = computed(() => {
        return store.getters.getStatusCategory;
      });
  
      const onSubmit = (values, res) => {
        const payload = {
          id: values._id || undefined,
          name: values.name,
          description: values.description,
          code: values.name.substring(0, 3).toUpperCase(),
          status_category_id: categoryValueId.value,
          workspace_id: activeWorkspace.value._id,
        };
  
        if (route.params.id == "asset-dimension") {
          const payloadDimention = {
            id: values._id || undefined,
            name: values.name,
            long_name: values.name,
            description: values.description,
            code: values.name.substring(0, 3).toUpperCase(),
            // dimension_category_id: dimentionValueId.value,
            workspace_id: activeWorkspace.value._id,
          };
          if (drawerStatus.value === "add") {
            store
              .dispatch(Actions.CREATE_ASET_DIMENSION, payloadDimention)
              .then((response) => {
                toastSuccess(
                  `${t("common.success")} ${t("common.add")} ${
                    assetInfo.value?.name
                  }`
                );
                console.log("response create", response);
                closeDrawer();
              })
              .catch((err) => {
                toastError(
                  `${t("common.failed")} ${t("common.add")} ${
                    assetInfo.value?.name
                  }`
                );
                console.log("value error", err);
              })
              .finally(() => {
                closeDrawer();
              });
            closeDrawer();
          } else if (drawerStatus.value === "edit") {
            store
              .dispatch(Actions.UPDATE_ASET_DIMENSION, payloadDimention)
              .then((response) => {
                toastSuccess(
                  `${t("common.success")} ${t("common.edit")} ${
                    assetInfo.value?.name
                  }`
                );
                console.log("response edit", response);
                closeDrawer();
              })
              .catch((err) => {
                toastError(
                  `${t("common.failed")} ${t("common.edit")} ${
                    assetInfo.value?.name
                  }`
                );
                console.log("value error", err);
              })
              .finally(() => {
                closeDrawer();
              });
            closeDrawer();
          }
        } else {
          if (drawerStatus.value === "add") {
            store
              .dispatch(Actions.CREATE_CATEGORY_ASSETS, payload)
              .then((response) => {
                toastSuccess(
                  `${t("common.success")} ${t("common.add")} ${
                    assetInfo.value?.name
                  }`
                );
                console.log("response create", response);
                closeDrawer();
              })
              .catch((err) => {
                toastError(
                  `${t("common.failed")} ${t("common.add")} ${
                    assetInfo.value?.name
                  }`
                );
                console.log("value error", err);
              })
              .finally(() => {
                closeDrawer();
              });
            closeDrawer();
          } else if (drawerStatus.value === "edit") {
            store
              .dispatch(Actions.UPDATE_CATEGORY_ASSETS, payload)
              .then((response) => {
                toastSuccess(
                  `${t("common.success")} ${t("common.edit")} ${
                    assetInfo.value?.name
                  }`
                );
                console.log("response edit", response);
                closeDrawer();
              })
              .catch((err) => {
                toastError(
                  `${t("common.failed")} ${t("common.edit")} ${
                    assetInfo.value?.name
                  }`
                );
                console.log("value error", err);
              })
              .finally(() => {
                closeDrawer();
              });
            closeDrawer();
          }
        }
      };
  
      const addAssetSchema = Yup.object().shape({
        description: Yup.string().required().label("Description"),
        category: Yup.string().required().label("Jenis Aset"),
      });
  
      const deleteCategory = (data) => {
        if (route.params.id == "asset-dimension") {
          store
            .dispatch(Actions.DELETE_ASET_DIMENSION, data)
            .then((response) => {
              toastSuccess(
                `${t("common.success")} ${t("common.delete")} ${
                  assetInfo.value?.name
                }`
              );
              console.log("response delete", response);
            })
            .catch((err) => {
              toastError(
                `${t("common.failed")} ${t("common.delete")} ${
                  assetInfo.value?.name
                }`
              );
              console.log("value error", err);
            })
            .finally(() => {
              console.log("sukses");
            });
        } else {
          store
            .dispatch(Actions.DELETE_CATEGORY_ASSETS, data)
            .then((response) => {
              toastSuccess(
                `${t("common.success")} ${t("common.delete")} ${
                  assetInfo.value?.name
                }`
              );
              console.log("response delete", response);
            })
            .catch((err) => {
              toastError(
                `${t("common.failed")} ${t("common.delete")} ${
                  assetInfo.value?.name
                }`
              );
              console.log("value error", err);
            })
            .finally(() => {
              console.log("sukses");
            });
        }
        getAssetDetail();
        getAssetsList();
      };
  
      watch(
        () => route.path,
        () => {
          getAssetDetail();
          getAssetsList();
          categoryValueId.value = route.params.id;
        },
        { immediate: true, deep: true }
      );
      return {
        checkPermission,
        checkPermissionUpdate,
        drawer,
        images,
        statusPerolehan,
        closeDrawer,
        listStatusPerolehan,
        DrawerModeEnum,
        openDrawer,
        drawerStatus,
        getJenisAset,
        getAssetsList,
        assetInfo,
        onSubmit,
        Form,
        addAssetSchema,
        formAsetData,
        deleteCategory,
        dimentionValueId,
        dimentionCategories,
      };
    },
  };
  